import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";
import routes from "../routes";
import {ApiContextProvider} from "../context/api-context";
var ps;

function Dashboard(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  return (
      <ApiContextProvider>
        <div className="wrapper">
          <Sidebar
              {...props}
              routes={routes}
              bgColor={backgroundColor}
              activeColor={activeColor}
          />
          <div className="main-panel" ref={mainPanel}>
            <Navbar {...props} />
            <Routes>
              {routes.map((prop, key) => {
                return (
                    <Route
                        path={prop.path}
                        element={prop.component}
                        key={key}
                        exact
                    />
                );
              })}
            </Routes>
            <Footer fluid/>
          </div>
        </div>
      </ApiContextProvider>
  );
}

export default Dashboard;
