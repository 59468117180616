import React, {useState} from "react"
import {Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";

export const VisitVideo = ({visit}) => {
    const src = `/profile/vid/${visit.profileGuid}`;
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const [validMsg, setValidMessage] = useState("");

    const handleDownload = (fileName = "twoja-bajka.mp4") => {
        axios.get(src, {responseType: "blob"})
            .then((res) => {
                fileDownload(res.data, fileName)
            });
    }

    const handleErrorLoadingMovie = (e) => {
        setValid(true);
        setValidMessage("Wystąpił problem z przygotowaniem filmu, spróbuj za chwilę. ")
    }

    return (
        <Col lg={"6"} md={"6"} sm={"6"}>
            <h4 className={"header"}>Film</h4>

            <div className={"container-fluid"}>
                <Card>
                    <CardHeader>
                        {!loading ? (
                            <CardTitle>Gratulacje!!! To bajka, którą udało wyreżyserować</CardTitle>
                        ) : (
                            <CardTitle>
                                {valid ? (
                                    <Alert color={"danger"}>{validMsg}</Alert>
                                ) : (
                                    <React.Fragment>
                                        Trwa przygotowywanie Twojego filmu. <br/>
                                        <i>Może to potrwać kilka minut</i>
                                    </React.Fragment>
                                )}
                            </CardTitle>
                        ) }
                    </CardHeader>
                    <CardBody>
                        <video
                            onCanPlayThrough={() => setLoading(false)}
                            onError={handleErrorLoadingMovie}
                            className={"w-100"}
                            src={src}
                            autoPlay={false}
                            controls
                        />
                    </CardBody>
                    <CardFooter>
                        {!loading ? (
                            <Button className={"btn btn-primary"} onClick={() => handleDownload()}>Pobierz</Button>
                        ) : (
                            <Spinner>
                                Loading...
                            </Spinner>
                        )}
                    </CardFooter>
                </Card>
            </div>
        </Col>
    )
}