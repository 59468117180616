import axios from "axios";

const send = (method, url, token = null, body = null) => {
    return axios({
        url,
        method,
        headers: token ? {
            Authorization: token
        } : {},
        data: body,
    }).then((response) => {
        return response
    })
}


export const HttpService = {
    send
}