import React from "react"
import {Button, Card, CardBody, CardFooter} from "reactstrap";
import {Link} from "react-router-dom";

export const VisitImage = ({profile, image, handleClickImage}) => {
    const src = `/profile/photo/${profile.profileGuid}/${image}`


    return (
        <Card key={image}>
            <CardBody>
                <img onClick={() => handleClickImage(src) } alt={image} src={src}/>
            </CardBody>
            <CardFooter>
                <Link className={"btn btn-primary btn-block"} to={src} target="_blank" download>
                    <i className="nc-icon nc-cloud-download-93"></i>
                </Link>
            </CardFooter>
        </Card>
    )
}