import React, {useEffect, useState} from "react";
import authService from "../../services/AuthorizeService";
import {ApplicationPaths, QueryParameterNames} from "../../../components/api-authorization/ApiAuthorizationConstants";
import {Navigate, Outlet, useNavigate} from "react-router-dom";

export const AuthorizeRoute = (props) => {
    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let _subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();
        return () => {
            authService.unsubscribe(_subscription);
        }
    }, []);


    const authenticationChanged = async () => {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    }
    const populateAuthenticationState = async () => {
        const auth = await authService.isAuthenticated();
        setReady(true);
        setAuthenticated(auth)
    }

    let link = document.createElement("a");
    link.href = props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`.replaceAll("undefined", "");
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

    if (!ready) {
        return <div></div>;
    }
    else if (authenticated) {
        return (<Outlet/>);
    }
    else {
        return (<Navigate to={redirectUrl}/>)
    }

}