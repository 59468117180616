import React from "react"
import Dashboard from "./views/Dashboard.js";
import {AddVisits} from "./views/Visits/AddVisits";

var routes = [
  {
    path: "/dashboard",
    name: "Pulpit nawigacyjny",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    layout: "/admin",
    inSidebar: true,
  },
  {
    path: "/dashboard/add-visit",
    name: "Nowa wizyta",
    icon: "nc-icon nc-bank",
    component: <AddVisits />,
    layout: "/admin",
    inSidebar: false
  }
];
export default routes;
