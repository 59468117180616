import React, {useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import moment from "moment/moment";
import {VisitImages} from "./VisitImages";
import {VisitVideo} from "./VisitVideo";

export const SingleVisit = ({profile}) => {

    return (
        <Col key={profile.profileGuid} lg="12" md="12" sm="12">
            <Card>
                <CardHeader>
                    <h5>Wizyta z dnia: <b>{moment(profile.dateVisited).format("DD-MM-YYYY HH:mm")}</b></h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <VisitImages visit={profile} />
                        <VisitVideo visit={profile} />
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}