import React from "react";
import {ApiContext} from "../api.context";
import {useEffect, useState} from "react";
import authService from "../../../services/AuthorizeService";

export const ApiContextProvider = ({children}) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [auth, setAuth] = useState(null)
    const [userId, setUserId] = useState(null);
    const [bearer, setBearer] = useState(null);
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        let _subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();
        return () => {
            authService.unsubscribe(_subscription);
        }
    }, []);

    const authenticationChanged = async () => {
        setAuthenticated(false);
        await populateAuthenticationState();
    }
    const populateAuthenticationState = async () => {
        const auth = await authService.isAuthenticated();
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setAuth(authService);
        setUserId(user ? user.sub : null);
        console.log(auth);
        setAuthenticated(auth);
        setBearer(`Bearer ${token}`)
    }

    const getData = async () => {
        const response = await fetch('profile', {
            headers: !bearer ? {} : {'Authorization': bearer}
        });
        const data = await response.json();
        setProfiles(data);
        return data;
    }

    return  (
        <ApiContext.Provider value={{
            userId: userId,
            bearer: bearer,
            profiles: profiles,
            authenticated: authenticated,
            getData: getData
        }}>
            {children}
        </ApiContext.Provider>
    )
}