import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Col} from "reactstrap";
import {useNavigate} from "react-router-dom";

export const CardAddVisit = () => {
    const navigate = useNavigate();
    return (
        <Col lg="3" md="6" sm="6">
            <Card className="card-pricing" onClick={(e) => navigate("/admin/dashboard/add-visit")}>
                <CardHeader>
                    <h6 className="card-category">Nowa wizyta</h6>
                </CardHeader>
                <CardBody>
                    <div className="card-icon icon-primary">
                        <i className="nc-icon nc-simple-add" />
                    </div>
                </CardBody>
                <CardFooter>
                    <Button
                        className="btn-round"
                        color="primary"
                        type={"button"}
                        onClick={(e) => e.preventDefault()}
                    >
                        Dodaj wizytę
                    </Button>
                </CardFooter>
            </Card>
        </Col>
    )
}