import React from "react"
import {Button, Col, Row} from "reactstrap";
import {VisitImage} from "./VisitImage";
import ReactBSAlert from "react-bootstrap-sweetalert";

export const VisitImages = ({visit}) => {
    const [alert, setAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert(null);
    };

    const onImageClick = (src) => {
        setAlert(
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title={
                    <img src={src} alt={"image"} />
                }
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                btnSize=""
                confirmBtnText={"Zamknij"}
            />
        );
    }

    return (
        <>
            {alert}
            {visit.imagesList?.length > 0 && (
            <Col lg={"6"} md={"6"} sm={"6"}>
                <h4 className={"header"}>Zdjęcia</h4>
                <div className={"container-fluid"}>
                    <Row>
                        {visit.imagesList?.map((image, idx) =>  (
                            <Col key={idx} lg={3} md={6} sm={12}>
                                <VisitImage image={image} profile={visit} handleClickImage={onImageClick} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Col>
            )}
        </>
    )
}