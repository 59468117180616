import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes, Navigate, Outlet} from "react-router-dom";
//import registerServiceWorker from './registerServiceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import "./admin/assets/css/paper-dashboard.css";
import "./admin/assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "./admin/layouts/Admin";
import {AuthorizeRoute} from "./admin/components/Authorize/AuthorizeRoute";
import {ApplicationPaths, LoginActions, LogoutActions} from "./components/api-authorization/ApiAuthorizationConstants";
import {Login} from "./components/api-authorization/Login";
import {Logout} from "./components/api-authorization/Logout";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename={baseUrl}>
      <Routes>
          <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
          <Route path="/admin/" element={<AuthorizeRoute />}>
              <Route path="*" element={<AdminLayout />} />
          </Route>
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} >
              <Route exact path={ApplicationPaths.Login} element={<Login action={LoginActions.Login}></Login>} />
              <Route exact path={ApplicationPaths.LoginFailed} element={<Login action={LoginActions.LoginFailed}></Login>} />
              <Route exact path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback}></Login>} />
              <Route exact path={ApplicationPaths.Profile} element={<Login action={LoginActions.Profile}></Login>} />
              <Route exact path={ApplicationPaths.Register} element={<Login action={LoginActions.Register}></Login>} />
              <Route exact path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout}></Logout>} />
              <Route exact path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback}></Logout>} />
              <Route exact path={ApplicationPaths.LoggedOut} element={<Logout action={LogoutActions.LoggedOut}></Logout>} />
          </Route>
      </Routes>
  </BrowserRouter>
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

