import {
    Alert,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import ReactDatetime from "react-datetime";
import React, {useEffect, useState} from "react";
import {Html5QrcodeScanner, Html5QrcodeScanType} from "html5-qrcode";
import validator from 'validator';
import {useApiContext} from "../../context/api-context/hooks/useApiContext";
import {useNavigate} from "react-router-dom";
import {HttpService} from "../../services/HttpService";
import {IsMobile} from "../../services/IsMobile";

export const AddVisits = () => {
    const navigate = useNavigate();
    const { bearer, userId } = useApiContext();
    const [isValid, setIsValid] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [date, setDate] = useState(null);
    const [camera, setCamera] = useState(false);
    const [scanner,setScanner] = useState();
    const [validMsg, setValidMsg] = useState();
    const [onProgress, setOnProgress] = useState(false)
    const [isMobile] = useState(IsMobile())

    const success = (result) => {
        if(validator.isUUID(result))
        {
            console.log(result);
            setQrCode(result);
            scanner?.clear();
        }
    }
    const error = (error) => {
        // console.warn(error);
    }

    useEffect(() => {
        const scan =  new Html5QrcodeScanner("reader",{
            qrBox: {
                width: 250,
                height: 250
            },
            supportedScanTypes: [
                Html5QrcodeScanType.SCAN_TYPE_CAMERA
            ],
            videoConstraints: {
                facingMode: "environment"
            },
            fps: 5,
        })
        setScanner(scan);
        return () => {
            console.log("DESTUCTOR")
            scan.clear();
            scanner?.clear();
        }
    }, []);

    useEffect(() => {
        if(camera === true)
            scanner.render(success, error);
        else if(camera === false)
            scanner?.clear()

    }, [camera]);

    useEffect(() => {
        if(qrCode && date && validator.isUUID(qrCode))
            setIsValid(true)
        else
            setIsValid(false);
    }, [qrCode, date]);

    const createModel = () => {
        return {
            token: qrCode,
            date: date
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setOnProgress(true);

        HttpService.send("POST", "profile", bearer, createModel() )
            .then((res) => {
                console.log(res);
                setValidMsg(null);
                navigate("/admin/dashboard")
            })
            .catch(error => {
                setValidMsg("Błąd wewnętrzny, spróbuj ponownie za chwilę.");
            })
            .finally(() => {
                setOnProgress(false);
            })
    }

    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="10">
                    <Card>
                        <CardHeader>
                            <CardText tag={"div"}>
                                <CardTitle tag={"h4"}>Dodaj nową wizytę</CardTitle>
                                <p className={"card-category text-lowercase"}>
                                    Przygotuj kartę reżysery, na której jest kod QR z rozgrywki
                                </p>
                            </CardText>
                        </CardHeader>
                        <CardBody>
                            {validMsg && (
                                <Alert color={"danger"}>
                                    {validMsg}
                                </Alert>
                            )}
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <div id="reader"></div>
                                { camera ? (
                                    <Button color="warning" onClick={() => setCamera(false)}>
                                        Wyłacz aparat
                                    </Button>
                                ) : (
                                    <Button color="warning" onClick={() => setCamera(true)}>
                                        Użyj aparatu
                                    </Button>
                                ) }


                                <FormGroup>
                                    <label>QrCode</label>
                                    <Input placeholder="Wprowadź kod" type="text" value={qrCode ? qrCode : ""}
                                           onChange={(e) => setQrCode(e.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Data odwiedzin</label>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Data",
                                            value: date ? date : ""
                                        }}
                                        onChange={(e) => setDate(e.format("DD-MM-YYYY"))}
                                        timeFormat={false}
                                    />
                                </FormGroup>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button className="btn-round" color="info" type="button" disabled={!isValid || onProgress} onClick={handleOnSubmit}>
                                Dodaj
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </div>
        </>
    )
}