import React, {useEffect, useState} from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col, Button, TabPane, TabContent, NavLink, NavItem, Nav,
} from "reactstrap";
import {useApiContext} from "../context/api-context/hooks/useApiContext";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {SingleVisit} from "./Visits/SingleVisit";
import {CardAddVisit} from "./Visits/CardAddVisit";

const Dashboard = () => {
  const {bearer, authenticated, getData, profiles} = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    if(authenticated === true) {
      getData();
    }
  }, [authenticated]);


  return (
    <div className="content">
      <Row>
        {profiles && profiles.length > 0 &&
            profiles.map((item, idx) => <SingleVisit key={idx} profile={item}/>)
        }
      </Row>
      <Row>
        <CardAddVisit />
      </Row>
    </div>
  );
}

export default Dashboard;
